body {
    color: #463e37 !important;
    font-family: "Poppins", serif !important;
    font-size: 1.1rem !important;
}

a {
    color: #463e37 !important;
    text-decoration: none !important;
}

a:hover {
    color: #08A4BD !important;
}

.btn {
    border-radius: 0 !important;
    padding: 10px 20px 10px 20px !important;
    text-transform: uppercase;
}

.btn-primary {
    background-color: #E9D1AF !important;
    border: none !important;
    color: #463e37 !important;
}

.loader {
    animation: rotation 1.5s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
